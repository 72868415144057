

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* html, */

.skill{
  /* background: #ecf0f1; */
  /* color: #444; */
  font-family: "Lato", Tahoma, Geneva, sans-serif;
  font-size: 24px;
  padding: 10px;
}
.set-size {
  font-size: 10em;
}

.charts-container:after {
  clear: both;
  content: "";
  display: table;
}

.pie-wrapper {
  height: 1em;
  width: 1em;
  float: left;
  margin: 15px;
  position: relative;
}
/* .pie-wrapper:nth-child(3n+1) {
  clear: both;
} */
.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .pie .half-circle {
  height: 100%;
  width: 100%;
  border: 0.1em solid #3498db;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .label {
  background: #34495e;
  border-radius: 50%;
  bottom: 0.4em;
  color: #ecf0f1;
  cursor: default;
  display: block;
  font-size: 0.25em;
  left: 0.4em;
  line-height: 2.8em;
  position: absolute;
  right: 0.4em;
  text-align: center;
  top: 0.4em;
}
.pie-wrapper .label .smaller {
  color: #bdc3c7;
  font-size: 0.45em;
  padding-bottom: 20px;
  vertical-align: super;
}
.pie-wrapper .shadow {
  height: 100%;
  width: 100%;
  border: 0.1em solid rgb(33,37,41);
  border-radius: 50%;
}
.pie-wrapper.style-2 .label {
  background: none;
  /* color: #0d6efd; */
}
.pie-wrapper.style-2 .label .smaller {
  color: rgb(177, 133, 22);
}
.pie-wrapper.progress-5 .pie .half-circle {
  border-color: rgb(218,165,32);
}
.pie-wrapper.progress-5 .pie .left-side {
  transform: rotate(18deg);
}
.pie-wrapper.progress-5 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-10 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-10 .pie .left-side {
  transform: rotate(36deg);
}
.pie-wrapper.progress-10 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-15 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-15 .pie .left-side {
  transform: rotate(54deg);
}
.pie-wrapper.progress-15 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-20 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-20 .pie .left-side {
  transform: rotate(72deg);
}
.pie-wrapper.progress-20 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-25 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-25 .pie .left-side {
  transform: rotate(90deg);
}
.pie-wrapper.progress-25 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-30 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-30 .pie .left-side {
  transform: rotate(108deg);
}
.pie-wrapper.progress-30 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-35 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-35 .pie .left-side {
  transform: rotate(126deg);
}
.pie-wrapper.progress-35 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-40 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-40 .pie .left-side {
  transform: rotate(144deg);
}
.pie-wrapper.progress-40 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-45 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-45 .pie .left-side {
  transform: rotate(162deg);
}
.pie-wrapper.progress-45 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-50 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-50 .pie .left-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-50 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-55 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-55 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-55 .pie .left-side {
  transform: rotate(198deg);
}
.pie-wrapper.progress-55 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-60 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-60 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-60 .pie .left-side {
  transform: rotate(216deg);
}
.pie-wrapper.progress-60 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-65 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-65 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-65 .pie .left-side {
  transform: rotate(234deg);
}
.pie-wrapper.progress-65 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-70 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-70 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-70 .pie .left-side {
  transform: rotate(252deg);
}
.pie-wrapper.progress-70 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-75 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-75 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-75 .pie .left-side {
  transform: rotate(270deg);
}
.pie-wrapper.progress-75 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-80 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-80 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-80 .pie .left-side {
  transform: rotate(288deg);
}
.pie-wrapper.progress-80 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-85 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-85 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-85 .pie .left-side {
  transform: rotate(306deg);
}
.pie-wrapper.progress-85 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-90 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-90 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-90 .pie .left-side {
  transform: rotate(324deg);
}
.pie-wrapper.progress-90 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-95 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-95 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-95 .pie .left-side {
  transform: rotate(342deg);
}
.pie-wrapper.progress-95 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-100 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-100 .pie .half-circle {
  border-color:  rgb(218,165,32);
}
.pie-wrapper.progress-100 .pie .left-side {
  transform: rotate(360deg);
}
.pie-wrapper.progress-100 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;
}
.pie-wrapper--solid:before {
  border-radius: 0 100% 100% 0/50%;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  transform-origin: left;
}
.pie-wrapper--solid .label {
  background: transparent;
}
.pie-wrapper--solid.progress-65 {
  background: linear-gradient(to right, #e67e22 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-65:before {
  background: #e67e22;
  transform: rotate(126deg);
}
.pie-wrapper--solid.progress-25 {
  background: linear-gradient(to right, #9b59b6 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-25:before {
  background: #34495e;
  transform: rotate(-270deg);
}
.pie-wrapper--solid.progress-88 {
  background: linear-gradient(to right, #3498db 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-88:before {
  background: #3498db;
  transform: rotate(43.2deg);
}