@media (min-width: 640px) {
  .project-carousel, .project-content {
    width: 50%;
    height: 100%;
  }
}

html{
  font-family: Titillium Web regular,sans-serif; 
}

.page-title{
    width: 100%;
}
  
.navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* padding: 20px; */
  /* background-color: #333; */
  z-index: 999;
  background: linear-gradient(to top, #000, rgba(0,0,0,0.8), rgba(0,0,0,0.0));
}

.navbar button {
  transition: all 0.3s ease;
  color: #fff;
  background-color: transparent;
  border: none;
  text-align: left;
  /* padding: 10px; */
  font-size: 20px;
  border-bottom: 2px solid rgb(126, 10, 10); 
}

.navbar button.active {
  font-size: 40px;
  font-weight: bold;
  border-bottom: 4px solid #ffd700;
}

.btn-secondary {
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: gold;
  --bs-btn-bg: black; 
  --bs-btn-color: gold;
  --bs-btn-border-color: gold;
  --bs-btn-hover-border-color: black;
}

.btn-primary {
  --bs-btn-hover-color: rgb(126, 10, 10); 
  --bs-btn-hover-bg: white;
  --bs-btn-bg: rgb(126, 10, 10); 
  --bs-btn-color: white;
  --bs-btn-border-color: gold;
  --bs-btn-hover-border-color: rgb(126, 10, 10); 
  font-weight: bold;
}

.project {
  margin-bottom: 20px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  border-width: 3px;
  border-color: rgb(218,165,32);
}

.project-name {
  font-weight: bold;
  animation: fadeIn 1s;
  color: rgb(218,165,32);

}

.project-title-wrapper{
    /* text-align: center; */
}

.project-placeholder {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-technologies .badge {
  margin-right: 10px;
  background-color: rgb(218,165,32);
  color: black;
  opacity: 0;

}

.project-links .btn {
  margin-top: 10px;
  margin-right: 5px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
}

.project-carousel {
  max-height: 50vh !important;
}

.carousel .slide img {
  object-fit: contain;
  max-height: 100%;
}

@keyframes fadeRotate {
  from { opacity: 0;
    transform: rotate(0deg); }
  to { opacity: 1; 
    transform: rotate(360deg);}
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(100%);
    opacity: 0; }
  to { transform: translateY(0);
    opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateX(-100%);
    opacity: 0; }
  to { transform: translateX(0);
    opacity: 1; }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { transform: scale(0.1); transform: translateY(100%); opacity: 0;}
  50% { transform: scale(1.2); }
  100% { transform: scale(1); transform: translateY(0%); opacity: 1;}
}

.pulse-animate{
  animation: pulse 2s ease-in-out;
}

.project-animate {
  animation: slideUp 1s ease-in-out;
}

.experience-animate {
  animation: fadeIn 1s ease-in-out;
}

.rotate-animate{
  animation: rotate 1s ease-in-out;
}

.fade-animate{
  animation: fadeIn 1s ease-in-out;
}

.slide-animate{
  animation: slideUp 1s ease-in-out;
}

.fade-rotate-animate{
  animation: fadeRotate 1s ease-in-out;
}

.fade-forward {
  animation: fadeIn 1s ease-in-out forwards;
}

.slide-forward{
  animation: slideIn 1s ease-in-out forwards;
}

.slide-up{
  animation: pulse 2s ease-in-out forwards;
}


@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes slideIn {
  from { transform: translateX(100%);}
  to { transform: translateX(0);}
}

.typing {
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid gold; /* The cursor */
  width: 0;
  animation: typing 2s steps(200, end) forwards, 
              blink-caret .25s step-end infinite, 
              hide-caret 0s 2s forwards;
              /* slideIn 2s linear forwards; */
}

/* The blinking cursor */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: gold; }
}

@keyframes hide-caret {
  to { border-color: transparent; }
}



.portfolio-container {
  padding-top: 100px;
  width: 90%;
  border-radius: 10px;
  /* background-color: #333; */
  color: #fff;
  /* padding-left: 20px;
  padding-right: 20px; */
  /* margin-left: 1em;
  margin-right: 1em; */

}

.portfolio-container {
  transition: background-color 0.5s ease;
}

.bio-pic {
  transition: opacity 0.5s ease;
}

.bio-section{
  display: flex;
  flex-direction: column;
  /* padding-left: 5px; */
}

.matrix-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.headshot{
  max-height: 20vh;
  object-fit: contain;
  transition: all 1s ease;
  /* max-width: 200px; */
}

.skills-section{
  display: inline-block;
}

.skill{
  display: flex;
  flex-direction: column;
  float: left;
  /* padding: 10px; */
  margin: 10px;
  border-radius: 10px;
  /* min-width: 10vw;
  min-height: 40vh; */
  justify-content: space-evenly;

}

.skills-wrapper{
  /* overflow: auto; */
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.skill-name{
  /* padding-left: 5px; */
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  text-align: center;
  /* width: min-content; */
  /* align-self: center; */
  width: 6.25em;

}

.skill-div{
  align-self: center;
}

.skill-desc{
  font-size: 1em;
}

.style-2{
  font-size: 5em;
}

.role-selector {
  position: fixed;
  top: 0;
  /* left: 0; */
  width: 100%;
  background: linear-gradient(#000 30%, rgb(51, 51, 51, .2), rgb(51, 51, 51, .01));
  color: #fff;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
  left: 50%;
  transform: translate(-50%, 0);
}

.role-selector h2 {
  margin-bottom: 10px;
}


.experience {
  margin-bottom: 20px;
  background-color: rgba(218,165,32, .8);
  color: #000;
  font-weight: bolder;
  border-width: 3px;
  border-color: #000;
}

.experience-logo {
  /* width: 50px;
  height: 50px;
  border-radius: 50%; */
  margin-right: 20px;
  max-width: 10vw;
  min-width: 10vw;
}

.experience .card-header {
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid #444; */
}

.experience-details {
  display: flex;
  flex-direction: column;
}


.education {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: rgba(218,165,32, .8);
  color: black;
}

.education-title {
  font-size: 2em;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;

}

.education-course {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 10px;
}

.education-grade {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  font-size: XXX-large;
}

.education-description {
  margin-top: 20px;
}

.education-modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.module {
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  margin: 20px;
  width: calc(40% - 5px);
  text-align: center;
  opacity: 0;
}

.module-name {
  font-weight: bold;
}

.module-grade {
  font-style: italic;
  font-size: XXX-large;
}
